<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">基本信息</a-divider>
			<a-descriptions :column="2">
				<a-descriptions-item label="仓管">
					{{ detail.username||'--' }}
				</a-descriptions-item>
				<a-descriptions-item label="状态">
                    <div class="ware_box">
                        <div>{{ detail.status_name||'--' }}</div>
                        <div class="btn" v-if="detail.status<3">
                            <a-button class="a_btn" type="primary" @click="change_status">{{detail.status<2?"仓管确认":"司机确认"}}</a-button>
                        </div>
                    </div>
					
				</a-descriptions-item>
				<a-descriptions-item label="仓库">
                    <a-input v-if="detail.status<3" v-model:value="detail.storage_name" @click="change_ware" style="width: 250px;"></a-input>
                    <div v-else>{{ detail.storage_name||'--'}}</div>
					<!-- {{ detail.storage_name||'--'}} -->
				</a-descriptions-item>
				<a-descriptions-item label="仓库编号">
					{{ detail.storage_id }}
				</a-descriptions-item>
				<a-descriptions-item label="司机">
                    <a-input v-if="detail.status<3" v-model:value="detail.chauffeur" @click="change_driver" style="width: 250px;"></a-input>
                    <div v-else>{{ detail.chauffeur||'--'}}</div>
					<!-- {{ detail.chauffeur||'--'}} -->
				</a-descriptions-item>
				<a-descriptions-item label="司机电话">
					{{ detail.chauffeur_mobile||'--'}}
				</a-descriptions-item>
                <a-descriptions-item label="车牌">
                    <a-select v-if="detail.status<3" v-model:value="detail.truck_license" style="width: 250px;">
                        <a-select-option v-for="(item,index) in license_data" :key="index" :title="item" :value="item">
						{{ item }}
                        </a-select-option>
                    </a-select>
                    <div v-else>{{detail.truck_license}}</div>
                	<!-- {{detail.truck_license}} -->
                </a-descriptions-item>
			</a-descriptions>

			<a-divider orientation="left">订单信息</a-divider>	
			<!-- <div class="btn_box">
				<a-button class="a_btn" type="primary" @click="add_order">添加订单</a-button>
			</div> -->
            <div v-for="(item,index) in detail.order_info" style="margin-bottom: 20px;">
                <div>
                    <a-descriptions :column="2">
                        <a-descriptions-item label="经销商">
                            <a-input v-if="detail.status<3" v-model:value="item.seller_name" @click="change_seller(index)" style="width: 250px;"></a-input>
                            <div v-else>{{ item.seller_name||'--' }}</div>
                            <!-- {{ item.seller_name||'--' }} -->
                        </a-descriptions-item>
                        <a-descriptions-item label="电话">
                            {{ item.phone||'--' }}
                        </a-descriptions-item>
                        <a-descriptions-item label="经销商编号">
							{{ item.seller_id }}
                        </a-descriptions-item>
                    </a-descriptions>
                </div>
                <div>
                    <div>
                        <a-descriptions :column="2">
                            <a-descriptions-item label="产品明细">
                                <div class="table_box" style="background:#FAFAFA;height:50px">
                                    <div class="tr_class">产品名称</div>
                                    <div class="tr_class">录入数量</div>
                                    <div class="tr_class">核对数量</div>
                                    <div class="tr_class" style="margin-left: 100px;" v-if="detail.status<3">操作</div>
                                </div>
                                <div class="table_box" v-for="(v,k) in item.goods_data" style="height:50px">
                                    <div class="td_class">{{v.item_name}}</div>
                                    <div class="td_class">{{v.stock_count}}</div>
                                    <div class="td_class" v-if="detail.status<3">
                                        <a-input v-model:value="v.check_count"></a-input>
                                    </div>
                                    <div class="td_class" v-else>{{v.check_count}}</div>
                                    <div class="td_class" style="margin-left: 100px;" @click="del_goods(index,k)" v-if="detail.status<3">删除</div>
                                </div>
                                <!-- <a-table
                                    class="mt10"
                                    :columns="columns"
                                    :pagination="false"
                                    :dataSource="item.goods_data"
                                >
                                </a-table>			 -->
								<div v-if="detail.status<3">
									<a @click="add_goods(index)">
										<a-icon type="plus-circle" />
										<span style="padding-left: 5px">添加产品</span>
									</a>
								</div>
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                </div>
            </div>
			<div class="box_form box_form_btns">
                <a-button  v-if="detail.status<3" class="a_btn" type="primary" @click="save_change" style="margin-right: 30px;">保存</a-button>
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
        <SelectSeller @submit="submit_seller" :visible.sync="visible_seller"></SelectSeller>
        <WarehouseSelect @submit="submit_ware" :visible.sync="show_ware"></WarehouseSelect>
        <DriverSelect @submit="submit_driver" :visible.sync="show_driver"></DriverSelect>
        <EditPop ref="edit"  @handleSubmit="submit_goods" :form_data="form_data_seo" :visible.sync="visible_goods"></EditPop>
	</div>
</template>

<script>
	import {
		get_empty_detail,
        getGoodsSelect,
        saveEmptyBarrel,
        changeEmptyStatus
	} from '@/api/push.js'
import SelectSeller from "@/components/SelectSeller";
import WarehouseSelect from "@/components/WarehouseSelect";
import DriverSelect from "@/components/DriverSelect";
import EditPop from "@/components/EditPop";
    const columns = [
        {
            title: "产品名称",
            dataIndex: "item_name",
        },
        {
            title: "录入数量",
            dataIndex: "stock_count",
        },
        {
            title: "核对数量",
            dataIndex: "check_count",
        },
    ];

	export default {
		components: {
            SelectSeller,
            WarehouseSelect,
            DriverSelect,
            EditPop
		},
		data() {
			return {
                columns,
				show_ware:false,
                seller_index:0,
                seller_goods:0,
                visible_seller:false,
                show_driver:false,
				detail: {},
                license_data:[],
                visible_goods:false,
                form_data_seo: {
                    title: "添加产品",
                    list: [
                        {
                            type: "select",
                            name: "item_id",
                            title: "产品",
                            mode: "default",
                            options: {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: "请选择空桶"
                                    }
                                ],
                            },
                            list: []
                        },
                        {
                            type: "text",
                            name: "num",
                            title: "数量",
                            options: {
                                rules: [
                                    {
                                        required: true,
                                        message: "请填写数量"
                                    }
                                ]
                            },
                        },
                    ]
                },
                goods_data:[],
			}
		},
		created() {
			this.get_info()
		},
		methods: {
			get_info() {
				get_empty_detail({
					data: {
						id: this.$route.query.id,
					}
				}).then(res => {
					this.detail = res.data.list
                    this.license_data = this.detail.license_data
				})
			},
			change_ware() {
				this.show_ware = true
			},
            submit_ware({
			    data
            }) {
                this.detail.storage_name = data.storage_name
                this.detail.storage_id = data.storage_id
                this.show_ware = false
            },
			change_seller(index) {
                this.visible_seller = true;
                this.seller_index = index
			},
            submit_seller({
			    data
            }) {
                this.visible_seller = false;
                this.detail.order_info[this.seller_index].seller_name = data.seller_name
                this.detail.order_info[this.seller_index].phone = data.phone
                this.detail.order_info[this.seller_index].seller_id = data.seller_id
                this.detail.order_info[this.seller_index].sales_seller_id = data.sales_seller_id
                this.seller_index = 0;
            },
            change_driver() {
                this.show_driver = true;
            },
            submit_driver({
			    data
            }) {
                this.detail.chauffeur = data.chauffeur
                this.detail.chauffeur_mobile = data.chauffeur_mobile
                this.license_data = data.truck_license.split(',')
                this.detail.truck_license = this.license_data[0]
                this.show_driver = false
            },
            add_goods(index) {
                this.visible_goods = true
                this.seller_goods = index;
                this.get_goods()
            },
            submit_goods(e){
                this.visible_goods = false
                let add_goods = {};
                let goods_name = '';
                this.goods_data.forEach(item=>{
                    if (item.key == e.values.item_id) {
                        goods_name = item.value
                    }
                })
                // let index_num = 1;
                // this.detail.order_info[this.seller_goods].goods_data.forEach(item=>{
                //     if(item.item_id == e.values.item_id) {
                //         this.$message.error('已经有该商品了');
                //         this.seller_goods = 0;
                //         return true
                //     }
                // })
                add_goods = {item_id:e.values.item_id,item_name:goods_name,stock_count:e.values.num,check_count:e.values.num}
                this.detail.order_info[this.seller_goods].goods_data.push(add_goods)
                this.seller_goods = 0;
            },
            get_goods() {
                getGoodsSelect().then(res=>{
                    var goods = [];
                    res.data.list.forEach(item=>{
                        var g = {key:item.drp_id,value:item.goods_title}
                        goods.push(g)
                    })
                    this.goods_data = goods
                    this.form_data.list = this.$method.set_form_list(this.form_data_seo.list, 'item_id', 'list', goods)
                })
            },
            del_goods(index,k) {
                this.detail.order_info[index].goods_data.splice(k,1)
            },
            change_status() {
                changeEmptyStatus({
                    data:{
                        id:this.$route.query.id
                    },
                    Toast:true
                }).then(res=>{
                    this.get_info()
                })
            },
			add_order() {

			},
            save_change() {
                saveEmptyBarrel({
                    data:{
                        id: this.$route.query.id,
                        storage_id: this.detail.storage_id,
                        chauffeur_mobile: this.detail.chauffeur_mobile,
                        truck_license: this.detail.truck_license,
                        driver_id: this.detail.driver_id,
                        order_info:this.detail.order_info
                    }
                }).then(res => {
                    if (res.code == 1) {
                        this.$message.success(res.info)
                    }
                    this.get_info()
                })
            }
		}
	}
</script>

<style lang="less" scoped>

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.ware_box {
		display: flex;
		align-items: center;

		.btn {
			margin-left: 20px;
		}
	}

    // .btn_box {
    //     margin-bottom: 20px;
    // }

    .table_box {
        display: flex;
        width: 750px;
        .tr_class {
            color: #000;
            flex: 1;
            align-self: center;
            padding-left: 20px;
        }
        .td_class {
            flex: 1;
            align-self: center;
            padding-left: 20px;
        }
    }
    
</style>
